import React, { useState } from "react";
import phone from "../assets/images/phone.png";
import address from "../assets/images/address.png";
import state from "../assets/images/state.png";
const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
    resume: "",
  });
  const handleChange = (e) => {
    const { name, value, type } = e.target;

    // Validation for Phone Number (accepts only digits and +)
    if (type === "tel") {
      // Remove non-digit and non-plus characters
      const digitsAndPlusOnly = value.replace(/[^0-9+]/g, "");
      setFormData({ ...formData, [name]: digitsAndPlusOnly });
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, phoneNumber, subject, message } = formData;

    // Check if any required field is empty
    if (!name || !email || !phoneNumber || !subject || !message) {
      alert("Please fill in all required fields.");
      return;
    }

    const mailtoLink = `mailto:16ridabaig@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\nPhone Number: ${phoneNumber}\nMessage: ${message}`
    )}`;

    window.location.href = mailtoLink;
  };

  return (
    <div id="contact">
      <div className="contact-flex-main ">
        <div className="form">
          <form id="form">
            <h1 className="text-center">Contact Form</h1>
            <label>
              <span>
                Name <span className="red">*</span>
              </span>
              <input
                required
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </label>
            <br />

            <label>
              <span>
                Email <span className="red">*</span>
              </span>
              <input
                required
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </label>
            <br />

            <label>
              <span>
                Phone Number <span className="red">*</span>
              </span>
              <input
                required
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </label>
            <br />

            <label>
              <span>
                Subject <span className="red">*</span>
              </span>
              <input
                required
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
              />
            </label>
            <br />
            {/* 
        <label>
          <span>
            Resume here <span className="red">*</span>
          </span>
          <input
            type="file"
            name="resume"
            value={formData.resume}
            onChange={handleChange}
            required
          />
        </label>
        <br /> */}
            <label>
              <span>
                Message <span className="red">*</span>
              </span>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </label>
            <br />

            <button type="submit" id="submit-btn" onClick={handleSubmit}>
              Send
            </button>
          </form>
        </div>
      </div>
      <div className="contact-info">
        <div className="offices">
          <div className=" office">
            <div className="office-data">
              <h1>eWorx Head Office</h1>
              <span>
                {" "}
                <img src={address} alt="phone" className="contact_logo" />
                Upper Ground Floor, Vogue Towers, MM Alam Rd, Block C/2 Gulberg
                III
              </span>
              <span>
                <img src={state} alt="phone" className="contact_logo" /> Lahore,
                Punjab, Pakistan
              </span>
              <span>
                <img src={phone} alt="phone" className="contact_logo" />
                +92 300 9470892 <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span> 042-35315091 , 042-35315092</span>
              </span>
            </div>
            <div className="office-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10913.419560141007!2d74.35361485794456!3d31.507687597141267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919053d6b43e93d%3A0xa55df5c35220dd5b!2sDaftarkhwan%20%7C%20Vogue!5e0!3m2!1sen!2s!4v1704383276586!5m2!1sen!2s"
                className="map"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="eWorx head office Address"
              ></iframe>
            </div>
          </div>
          <div className=" office">
            <div className="office-data">
              {" "}
              <h1>eWorx bahria town, Lahore office</h1>
              <span>
                {" "}
                <img src={address} alt="phone" className="contact_logo" /> Rizco
                heights 29-DD, Sector D, Ground floor, Bahria town, Lahore
              </span>
              <span>
                <img src={state} alt="phone" className="contact_logo" /> Lahore,
                Punjab, Pakistan
              </span>
              {/* <span>
                <img src={phone} alt="phone" className="contact_logo" />{" "}
                xxxxxxxxxx{" "}
              </span> */}
            </div>
            <div className="office-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d109006.72207468053!2d74.08153460381548!3d31.373875661740563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3918ff303646d917%3A0x6e4128ffdd17b33b!2sRizco%20hights!5e0!3m2!1sen!2s!4v1704384351640!5m2!1sen!2s"
                className="map"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="Rizco heights Address"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="international-offices">
          <div className="canada office">
            <div className="office-data mt-5 mb-5">
              {" "}
              <h1>eWorx Canada Office</h1>
              <span><b>&nbsp;ASZ HR links (Partner Company)</b></span>
              <span>
                {" "}
                <img src={address} alt="phone" className="contact_logo" /> 47
                Kerrion Drive West , AJAX , ON L1Z1K2, Canada
              </span>
              <span>
                <img src={phone} alt="phone" className="contact_logo" />
                1-437-216-9815{" "}
              </span>
            </div>
          </div>
          <div className="uk office">
            <div className="office-data mt-5 mb-5">
              {" "}
              <h1>eWorx UK Office</h1>
              <span>
                {" "}
                <img src={address} alt="phone" className="contact_logo" />
                22 SOMERVELL ROAD HA2 8TU HARROW
              </span>
              <span>
                <img src={state} alt="phone" className="contact_logo" />
                LONDON, UK
              </span>
              <span>
                <img src={phone} alt="phone" className="contact_logo" />
                +44 7398 668460{" "}
              </span>
            </div>
          </div>
          <div className="usa office">
            <div className="office-data mt-5 mb-5">
              {" "}
              <h1>eWorx USA Office</h1>
              <span><b>&nbsp;Meta Pulse Plus LLC (Partner Company)</b></span>
              <span>
                {" "}
                <img src={address} alt="phone" className="contact_logo" />
                119 Rebecca Road, Bridgeport, WV26330, USA
              </span>
              <span>
                <img src={phone} alt="phone" className="contact_logo" />
                1-404-4448850{" "}
              </span>
            </div>
          </div>
          <div className="usa office">
            <div className="office-data mt-5 mb-5">
              <h1>eWorx USA Office</h1>
              <p><b>OZ Digital Consulting (Partner Company)</b></p>
              <span>&nbsp;500 W. Cypress Creek Road.</span>
              <span>
                <img src={address} alt="phone" className="contact_logo" />
                Suite 570, Fort Lauderdale, Florida, 33309. USA
              </span>
              <span>
                <img src={phone} alt="phone" className="contact_logo" />
                1800-443-5210
              </span>
            </div>
          </div>
          <div className="gcc office">
            <div className="office-data mt-5 mb-5">
              <h1>eWorx G.C.C Office</h1>
              <p><b>Proz Technologies  (Partner Company)</b></p>
              <span>
                <img src={address} alt="phone" className="contact_logo" />
                11th floor Al Waseef tower, Doha ,Qatar
              </span>
              <span>
                <img src={phone} alt="phone" className="contact_logo" />
                <span className="ms-1">00 974 4442 0050</span>
                <br />
                <span style={{marginLeft:'26px'}}>00 974 5577 5600</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
