import React from "react";
// import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "../css/style.css";
import web_logo from "../assets/images/web-logo.png";
import dm_logo from "../assets/images/dm-logo.png";
import account_logo from "../assets/images/account-logo.png";
import staff_logo from "../assets/images/staff-logo.png";
import software_logo from "../assets/images/software-logo.png";
import medical_logo from "../assets/images/medical-logo.png";
import globe from "../assets/images/globe.png";
import nokia from "../assets/images/nokia.png";
import citrix from "../assets/images/citrix.png";
import microsoft from "../assets/images/microsoft.png";
import ptcl from "../assets/images/ptcl.png";
import Qatalum from "../assets/images/Qatalum.png";
import solligenix from "../assets/images/solligenix.png";
import ibm from "../assets/images/ibm.png";
import huawei from "../assets/images/huawei.png";

export default function Home() {
  return (
    <>
      <section className="hero-container ">
        <div className="overlay"></div>
        <div className="intro-div">
          <h1>
            <span>Welcome to</span>
            <span>eWorx </span>{" "}
            <span data-text="International"> International</span>{" "}
          </h1>
          <p className="intro-text">
            eWorx was founded in 1997 in Lahore Pakistan. eWorx is over quarter
            of a century old premier IT enabled services company serving the
            local as well as international markets in the English speaking
            world. Even though we very happily work with very large companies
            also but we take pride and great satisfaction in working with small
            startups and SME sector companies the most, since these small very
            young vulnerable companies need our cost effective business
            management solutions the most in order to survive on very tight
            budgets.
          </p>
        </div>
      </section>
      <section className="about reveal">
        <div className="home-about">
          <div>
            <h2>Get to know us</h2>
            <p>
              eWorx provides a vast array of technical solutions to the most
              challenging business problems our customers face. Whether your
              business needs world class talent to develop mission critical
              software applications, project management expertise to bring
              projects to fruition, digital marketing services, accounting
              services, medical transcription and medical billing services and
              last but not least we supply a wide variety of skilled staff
              augmentation services, eWorx can provide excellent highly skilled
              cost effective resources for fulfilling your business needs and
              requirements.
            </p>

            <Link className="nav-links" to="/about" style={{ color: "white" }}>
              Explore More &rarr;
            </Link>
          </div>
          <div>
            <img src={globe} alt="" className="globe" />
          </div>
        </div>
      </section>
      <section className="services reveal">
        <h2>Our Services</h2>
        <div className="home-services">
          <div className="service">
            <Link to="/webDev" style={{ color: "white" }}>
              <div className="home-service-icons">
                <h3>Website Development </h3>
                <span>
                  <img src={web_logo} alt="" className="home-service" />
                </span>
              </div>

              <p>
                Leverage eWorx's expertise for a standout online presence. Our
                tailored solutions blend sleek designs and robust
                functionalities, ensuring a compelling and effective website
                experience.
              </p>
            </Link>
          </div>
          <div className="service">
            <Link to="/digitalMarket" style={{ color: "white" }}>
              <div className="home-service-icons">
                <h3>Digital Marketing </h3>
                <span>
                  <img src={dm_logo} alt="" className=" home-service" />
                </span>
              </div>
              <p>
                Digital marketing services help generate demand for your
                business by maximising customer engagement.Digital marketing
                services help customers to approach audiences through
                intelligent profiling.
              </p>
            </Link>
          </div>
          <div className="service">
            <Link to="/Accounting" style={{ color: "white" }}>
              <div className="home-service-icons">
                <h3>Accounting and Tax Advisory </h3>
                <span>
                  <img src={account_logo} alt="" className=" home-service" />
                </span>
              </div>
              <p>
                Proudly providing a skilled team of virtual accountants, we
                assist clients with diverse accounting needs. Our expertise
                spans a wide range of practices and technologies for
                comprehensive support.
              </p>
            </Link>
          </div>
          <div className="service">
            <Link to="/remoteStaff" style={{ color: "white" }}>
              <div className="home-service-icons">
                <h3>Remote Staffing Services </h3>
                <span>
                  <img src={staff_logo} alt="" className="home-service" />
                </span>
              </div>
              <p>
                Since 1997, our global Remote Staffing offers diverse skills.
                Our meticulous selection process ensures matching talented
                professionals with customer job openings for optimal
                satisfaction.
              </p>
            </Link>
          </div>
          <div className="service">
            <Link to="/medical" style={{ color: "white" }}>
              <div className="home-service-icons">
                <h3>Medical Transcription and Billing </h3>
                <span>
                  <img src={medical_logo} alt="" className="home-service" />
                </span>
              </div>
              <p>
                eWorx International excels in healthcare services, merging
                advanced technology and skilled professionals for precise
                medical transcription and billing, ensuring compliance with
                HIPAA regulations.
              </p>
            </Link>
          </div>
          <div className="service">
            <Link to="/softwareDev" style={{ color: "white" }}>
              <div className="home-service-icons">
                <h3>Software Development </h3>
                <span>
                  <img src={software_logo} alt="" className=" home-service" />
                </span>
              </div>
              <p>
                Experience innovation with eWorx's seasoned team. Our efficient
                and scalable software solutions, driven by the latest
                technologies, keep your business at the forefront of digital
                evolution.
              </p>
            </Link>
          </div>
        </div>
      </section>
      <section className="logos">
        <div className="logos-slide">
          <img src={ptcl} alt="logo" className="brandslogo" />
          <img src={microsoft} alt="logo" className="brandslogo" />
          <img src={solligenix} alt="logo" className="brandslogo" />
          <img src={citrix} alt="logo" className="brandslogo" />
          <img src={Qatalum} alt="logo" className="brandslogo" />
          <img src={ibm} alt="logo" className="brandslogo" />
          <img src={huawei} alt="logo" className="brandslogo" />
          <img src={nokia} alt="logo" className="brandslogo" />
        </div>
        <div className="logos-slide">
          <img src={ptcl} alt="logo" className="brandslogo" />
          <img src={microsoft} alt="logo" className="brandslogo" />
          <img src={solligenix} alt="logo" className="brandslogo" />
          <img src={citrix} alt="logo" className="brandslogo" />
          <img src={Qatalum} alt="logo" className="brandslogo" />
          <img src={ibm} alt="logo" className="brandslogo" />
          <img src={huawei} alt="logo" className="brandslogo" />
          <img src={nokia} alt="logo" className="brandslogo" />
        </div>
      </section>

    </>
  );
}
