import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import client1 from "../assets/images/oz.jpeg";
import client2 from "../assets/images/proz.jpeg";
import client3 from "../assets/images/jet.jpeg";
import client4 from "../assets/images/qatar.jpeg";
import client5 from "../assets/images/pak_army.jpeg";
import client6 from "../assets/images/huawei.jpeg";
import client7 from "../assets/images/unicom.jpeg";
import star from "../assets/images/star.png";
import halfstar from "../assets/images/half-star.png";
import award from "../assets/images/award.jpeg";

export default function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Sample testimonials data (can be moved to props or state)
  const testimonials = [
    {
      img: client1,
      name: "CEO, OZ digital Consulting USA",
      rating: 5,
      text: `eWorx has been our supplier for over 2 decades now. Their very professional remote staffing services and very high quality infrastructure along with very economical monthly rates helped us in delivering majority of our IT system projects for USA market. On top of that eWorx very economically priced services helped us increase our profitability alot.
      We highly recommend their very reliable and professional IT enabled services to any organisation so that they can also reap the benefits that we are enjoying with our business relationship with eWorx.
      `,
    },
    {
      img: client2,
      name: "CEO ProzTech, Qatar",
      rating: 4.5,
      text: `We have been working with eWorx since last many years. We wanted well trained IT engineers for banking sector of Qatar market. eWorx was able to provide their very professionally trained team of IT engineers within one month and till date that team is working with us and delivering in the IT departmens of Qatar banking sector. We are very satisfied with their quality of work, economical prices and timely deliveries. `,
    },
    {
      img: client3,
      name: "CEO, Jet health Solutions ",
      rating: 4.5,
      text: `We started our journey with the full support of eWorx. We were a startup IT product company for health care sector in USA. We wanted to develop our IT product but had very limited resources since we were a startup. eWorx showed a big heart and gave us same respect and value as an established company and  provided us at very economical rates a very good team of IT developers and IT product managers along with top quality infrastructure who made our dream product a reality. Our dream product Jet insurance started to work in the USA market and still going strong. Thankyou eWorx. 
`,
    },
    {
      img: client4,
      name: " Manager IT department, Qatalum, Qatar",
      rating: 4.5,
      text: `Sharepoint system was installed by eWorx at Qatalum(Qatar Aluminium) plant along with proper training to our staff in Qatar. Qatalum was very satisfied with the work done by eWorx. 
`,
    },
    {
      img: client5,
      name: "Project Manager, Pakistan Army",
      rating: 4.5,
      text: `eWorx installed and gave training to Pakistan Army internal project management teams about Microsoft EPM (Enterprise Project Management). Pakistan Army was very appreciative of the high quality work done by eWorx. 
`,
    },
    {
      img: client6,
      name: "Project Director, Huawei",
      rating: 4.5,
      text: `eWorx worked with Huawei and received 'Best potential Partner Award' for Lahore Safe City Telecom project from Huawei in 2017. eWorx has been our very dependable delivery Partner. `,
    },
    {
      img: client7,
      name: "CEO, Unicom",
      rating: 4.5,
      text: `eWorx has been our very reliable delivery partner in our various telecom projects for Mobilink, PTCL, Zong,
       Huawei in the Pakistan            market. In our future projects also we plan to benefit from their very professional and reliable project delivery capabilities.
`,
    },
  ];

  // Function to render stars based on rating
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    return (
      <>
        {[...Array(fullStars)].map((_, i) => (
          <img key={i} src={star} alt="star" />
        ))}
        {halfStar && <img src={halfstar} alt="half star" />}
      </>
    );
  };

  return (
    <div className="about-div">
     
      <section id="award">
        <h1 className="text-center">Best Potential Partner Award</h1>

        <img src={award} alt="Best Potantial Partner" id="award" />
      </section>

      <h1 className="mb-0">Client Testimonials</h1>

      <div className="container">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              testimonial={testimonial}
              renderStars={renderStars} // Pass renderStars as a prop
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}
function TestimonialCard({ testimonial, renderStars }) {
  const [expanded, setExpanded] = useState(false);
  const maxLength = 200;

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  const renderText = () => {
    if (testimonial.text.length <= maxLength) {
      return testimonial.text;
    }

    if (expanded) {
      return (
        <>
          {testimonial.text}{" "}
          <span className="read-more text-primary" onClick={toggleReadMore}>
            Hide
          </span>
        </>
      );
    } else {
      return (
        <>
          {testimonial.text.slice(0, maxLength)}...{" "}
          <span className="read-more text-primary" onClick={toggleReadMore}>
            See More
          </span>
        </>
      );
    }
  };

  return (
    <div className="item">
      <img src={testimonial.img} alt="client" />
      <div className="rating">
        <div className="d-flex flex-column justify-content-start align-items-start">
          <h3>{testimonial.name}</h3>
        </div>
        <div className="stars">{renderStars(testimonial.rating)}</div>
      </div>
      <p className="testimonialPara">{renderText()}</p>
    </div>
  );
}
