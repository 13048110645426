import React from "react";
import "../css/others.css";

export default function FAQs() {
  return (
    <section className="faq services-div">
      <h1>Frequently Asked Questions</h1>
      <div class="accordion  mt-3" id="accordionExample">
        <div class="accordion-item qno1">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              1. How long experience does eWorx have of delivering their IT
              enabled services for international customers in USA, Europe,
              Canada?
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              eWorx has been serving customers locally and internationally for
              over <span className="blue"> quarter of century</span> with their{" "}
              <b>
                Remote staffing services, Website development services, Software
                development outsourcing services, Accounting services
              </b>
              . For last few years <b>digital marketing services </b>
              and <b>medical transcription</b> and{" "}
              <b>medical billing services</b>.
            </div>
          </div>
        </div>
        <div class="accordion-item qno2">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              2. How much staff does eWorx have on panel in Pakistan?
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <span className="blue">250 to 300</span> wide variety of highly
              skilled and educated people on staff serving our international
              customers remotely from <b>Lahore, Pakistan</b> office on regular
              basis. <b>10 times more like 3000 people </b>
              having wide variety of skills, and highly educated individuals are
              on our panel available to start working on customer assignments.
            </div>
          </div>
        </div>
        <div class="accordion-item qno3">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              3. How to contact eWorx for getting price quotations for services
              required?
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Please <span className="blue">email us</span>, or contact us by
              sending message of your inquiry about our IT enabled services
              through our website{" "}
              <b>
                Contact us page, WhatsApp number, Facebook or Instagram pages
              </b>
              . We will get back to you promptly.
            </div>
          </div>
        </div>
        <div class="accordion-item qno4">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              4. Does eWorx have any offices in English speaking countries?
            </button>
          </h2>
          <div
            id="collapseFour"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              We have <b>our marketing offices</b> in{" "}
              <span className="blue">USA, Canada</span> and{" "}
              <span className="blue">UK</span> for convenience of our customers
              and ensuring our customers get excellent services from start till
              end of job.
            </div>
          </div>
        </div>
        <div class="accordion-item qno5">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              5. How can we know that eWorx is right vendor for us?
            </button>
          </h2>
          <div
            id="collapseFive"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Our <span className="blue">"one week free trial offer"</span> for
              all our services delivery samples should give good idea to our
              customers about quality of IT enabled services we deliver. If you
              like our services sample for one week you can continue otherwise
              you can stop after one week with no obligation to pay for that
              first trial week of services delivery. Other than that we have
              over quarter of a century experience of this business, and a long
              list of satisfied customers.
            </div>
          </div>
        </div>
        <div class="accordion-item qno6">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              6. Are the services of eWorx expensive or economical?
            </button>
          </h2>
          <div
            id="collapseSix"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Our services are very economical and reasonably priced considering
              the high quality standards of our IT enabled services we maintain.
            </div>
          </div>
        </div>
        <div class="accordion-item qno7">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              7. Does eWorx have good after sales services?
            </button>
          </h2>
          <div
            id="collapseSeven"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <span className="blue">Yes,</span> eWorx stand behind all their
              services delivered with full responsibility and commitment. We
              remain in touch with our customers on regular basis to ensure our
              services are performing well. If any problems arise, we take
              immediate corrective action and fix it.
            </div>
          </div>
        </div>
        <div class="accordion-item qno8">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              8. What industries does eWorx International cater to?
            </button>
          </h2>
          <div
            id="collapseEight"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              eWorx International serves various industries, including but not
              limited to{" "}
              <b>
                healthcare, finance, e-commerce, real estate, education, and
                more.
              </b>{" "}
              Our adaptable solutions can be tailored to suit diverse industry
              requirements.
            </div>
          </div>
        </div>
        <div class="accordion-item qno9">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              9. Can eWorx handle urgent or short-term projects?
            </button>
          </h2>
          <div
            id="collapseNine"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <span className="blue">Absolutely.</span> Our agile workforce and
              scalable resources enable us to accommodate urgent projects
              efficiently. <b>Contact us</b> with your project details, and
              we'll work to meet your timeline.
            </div>
          </div>
        </div>
        <div class="accordion-item qno10">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="false"
              aria-controls="collapseTen"
            >
              10. Are eWorx's services compliant with international standards
              and regulations?
            </button>
          </h2>
          <div
            id="collapseTen"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <span className="blue">Yes,</span> we adhere strictly to
              international standards and regulations relevant to our service
              offerings. Our commitment to compliance ensures that your projects
              meet global standards.
            </div>
          </div>
        </div>
        <div class="accordion-item qno11">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEleven"
              aria-expanded="false"
              aria-controls="collapseEleven"
            >
              11. What security measures does eWorx employ to protect client
              data?
            </button>
          </h2>
          <div
            id="collapseEleven"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Security is paramount to us. We implement robust measures,
              including{" "}
              <b>
                encryption, access controls, confidentiality agreements, and
                regular audits,
              </b>{" "}
              to safeguard your sensitive information.
            </div>
          </div>
        </div>
        <div class="accordion-item qno12">
          <h2 class="accordion-header">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwelve"
              aria-expanded="false"
              aria-controls="collapseTwelve"
            >
              12. Does eWorx provide training or on boarding for the
              solutions/services offered?
            </button>
          </h2>
          <div
            id="collapseTwelve"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <span className="blue">Yes,</span> we offer comprehensive training
              sessions and on boarding support to ensure that your team is
              proficient in utilizing the delivered solutions effectively.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
