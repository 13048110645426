import React from "react";
import career1 from "../assets/images/career1.png";
import application from "../assets/images/icons8-application-48.png";
import interview from "../assets/images/icons8-chat-40.png";
import offer from "../assets/images/icons8-job-offer-53 (1).png";

export default function Career() {
  return (
    <div>
      <div id="career">
        <div className="career-intro">
          <div>
            {" "}
            <h1>Careers</h1>
            <p className="career_tagline">
              Unlock your potential at <span className="blue">eWorx</span>
            </p>
            <p className="career_tagline">
              {" "}
              Join a dynamic team, drive impact, and thrive in a culture of
              innovation
            </p>
          </div>
        </div>
        <div className="main-career">
          <div className="img">
            <img src={career1} alt="" className="career-pic" />
          </div>
          <div className="career-para">
            At <span className="blue">eWorx,</span> we go beyond the
            conventional boundaries in the realm of IT enabled services,
            delivering a dynamic spectrum that includes cutting-edge Software
            development, Website Development, Digital marketing, Accounts and
            Tax Advisory, Remote Staffing Services as well as Medical
            Transcription and many more.As a hub of innovation, we provide
            technological solutions to the problems. We foster a work
            environment that encourages creativity, collaboration and continuous
            learning. Join us in shaping the future of IT, where your skills
            find purpose.
          </div>
        </div>
        <section className="recruitment reveal">
          <div>
            <h1>Our recruitment process</h1>
            <p>
              eWorx is dedicated to crafting top-notch software solutions while
              cultivating excellent work environment. Join us for cutting edge
              solutions and an environment that champions your success and
              growth.
            </p>
          </div>
          <div className="hiring-process reveal">
            <div className="career-flex-item">
              <div>
                <h2>Application</h2>
                <img
                  src={application}
                  alt="application"
                  className="career-logos"
                />
              </div>
              <p>
                Kindly email us your resume at careers@eworx.com.pk to get us
                know you better, we will thoroughly review your application.
              </p>
            </div>
            <div className="career-flex-item">
              <div>
                <h2>Interview</h2>
                <img src={interview} alt="interview" className="career-logos" />
              </div>
              <p>
                Let's acquaint ourselves with your expertise and experience in
                the field to assess your capabilities against our specific
                criteria effectively.
              </p>
            </div>
            <div className="career-flex-item">
              <div>
                <h2>Offer</h2>
                <img src={offer} alt="offer" className="career-logos" />
              </div>
              <p>
                Congratulations! You've successfully completed the process.
                Kindly sign the agreement to officially join eWorx.
              </p>
            </div>
          </div>
        </section>
        <section className="apply reveal">
          <h1>How to Apply?</h1>
          <p>Send your <b>resume, portfolio,</b> and <b>cover letter</b> to <span className="blue">careers@eworx.com.pk</span>. Let's shape the digital future together!</p>
        </section>
        <div className="job-openings reveal">
          <h1>Current Openings</h1>
          <div className="flex-job-openings">
            <div className="job webdev">
              <h3 className="job-title">Web Developer</h3>{" "}
              <p>
                Join our team as a Web Developer! If you're skilled in{" "}
                <b>HTML, CSS, JavaScript,</b> and have experience with{" "}
                <b>React or Angular</b>, we want you. Create responsive,
                user-friendly websites and stay ahead in web technologies. If
                you're passionate about innovation and detail-oriented,
                <span className="blue"> apply now!</span>
              </p>
              <h4 className="job-location">
                <span className="blue">Job Location :</span> Lahore, Pakistan
              </h4>
              <h3>Requirements:</h3>
              <ul>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Proficiency in <b>frontend technologies</b> HTML, CSS,
                    JavaScript, and React or Angular.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Familiarity with <b>back-end technologies </b>(Node.js,
                    Python) and
                    <b> databases </b>(MySQL, MongoDB).
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Hands-on experience in real-life web development projects.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Strong problem-solving skills and the ability to work
                    collaboratively in a team environment.
                  </p>
                </li>
              </ul>
              <h3>Qualifications:</h3>
              <ul>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    <b>Bachelor's degree</b> in <b>Computer Sciences</b>, or a related
                    area
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Excellent problem-solving and analytical skills.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Strong understanding of programming languages{" "}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Effective communication and teamwork abilities.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Proven ability to adapt and learn new technologies quickly.
                  </p>
                </li>
              </ul>
            </div>
            <div className="job digital-market">
              <h3 className="job-title">Digital Marketing Specialist</h3>

              <p>
                We are seeking a talented Digital Marketing Specialist to join
                our growing team. As a key player in our marketing department,
                you will be responsible for developing and executing digital
                strategies to drive online visibility, engagement, and
                conversion. If you're passionate about innovation and
                detail-oriented,
                <span className="blue"> apply now!</span>
              </p>
              <h4 className="job-location">
                <span className="blue">Job Location :</span> Lahore, Pakistan
              </h4>
              <h3>Requirements:</h3>
              <ul>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Create and manage digital marketing campaigns across various
                    channels, including social media, email, and search engines.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Analyze performance metrics and optimize campaigns for
                    maximum effectiveness.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Collaborate with cross-functional teams to ensure alignment
                    with overall marketing goals.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Stay updated on industry trends and implement best practices
                    in digital marketing.
                  </p>
                </li>
              </ul>
              <h3>Qualifications:</h3>
              <ul>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    <b>Bachelor's degree</b> in <b>Marketing</b>, <b>Communications</b>, or a
                    related area.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Proven digital marketing expertise in social media, SEO, and
                    email marketing.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Familiarity with analytics tools and a data-driven mindset.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Excellent communication and collaboration skills.
                  </p>
                </li>
                <li>
                  <p>
                    <span className="arrow">&#10148; </span>
                    Creative thinking and ability to generate innovative ideas.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
