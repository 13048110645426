import React from "react";
import "../css/others.css";
import citrix from "../assets/images/citrix.png";
import ptcl from "../assets/images/ptcl.png";
import Qatalum from "../assets/images/Qatalum.png";
import solligenix from "../assets/images/solligenix.png";
import huawei from "../assets/images/huawei.png";
import fda from "../assets/images/fda.png";
import Amerigroup from "../assets/images/Amerigroup.png";
import kerzner from "../assets/images/kerzner.png";
import hoerbiger from "../assets/images/hoerbiger.png";
import MCH from "../assets/images/MCH.jpg";
import fdoffs from "../assets/images/fdoffs.png";
import bc from "../assets/images/bc.png";
import qatar2 from "../assets/images/qatar2.png";
import qatar3 from "../assets/images/qatar3.jpeg";
import qatar4 from "../assets/images/qatar4.png";
import qatar6 from "../assets/images/qatar6.jpg";
import qatar10 from "../assets/images/qatar10.JPG";
import qatar12 from "../assets/images/qatar12.JPG";
import qatar7 from "../assets/images/qatar7.webp";
import qatar8 from "../assets/images/qatar8.png";
import qatar9 from "../assets/images/qatar9.png";
import qatar13 from "../assets/images/qatar13.JPG";
import pk3 from "../assets/images/pk3.png";
import pk4 from "../assets/images/pk4.jpeg";
import pk5 from "../assets/images/pk5.png";
import pk6 from "../assets/images/pk6.png";
import pk7 from "../assets/images/pk7.JPG";
import pk8 from "../assets/images/pk8.jpg";
import pk9 from "../assets/images/pk9.JPG";
import pk10 from "../assets/images/pk10.JPG";
import pk11 from "../assets/images/pk11.JPG";
import us1 from "../assets/images/us1.JPG";
import us2 from "../assets/images/us2.JPG";
import us3 from "../assets/images/us3.JPG";
import us4 from "../assets/images/us4.JPG";
import us5 from "../assets/images/us5.JPG";
import us6 from "../assets/images/us6.JPG";
import us7 from "../assets/images/us7.JPG";
import us8 from "../assets/images/us8.JPG";
import us9 from "../assets/images/us9.JPG";
import pk12 from "../assets/images/pk12.JPG";
import "../css/style.css";

export default function SuccessStories() {
  return (
    <div className=" services-div">
      <div className="clients">
        <h1>Our Clients</h1>
        <div className="clients-intro">
          <p>
            eWorx has been providing high quality IT enabled services to
            customers locally in <span className="blue">Pakistan</span> as well
            as internationally in <span className="blue">USA, Canada </span> and{" "}
            <span className="blue"> GCC countries.</span> Our customers spanning
            diverse industries have been genuinely happy and satisfied with the
            quality of our work. As a result we have very strong and long term
            relationships with our customers. eWorx is your reliable and
            trustworthy partner delivering high quality IT enabled services to
            our customers in various parts of the world for over quarter of a
            century.
          </p>
          <p>
            We have a long list of satisfied clients, out of that some of our
            top clients are mentioned below to give you idea about our diverse
            clientele.
          </p>
        </div>

        <div className="us-clients market-client">
          <h2>eWorx clients in USA through our marketing partners </h2>
          <div>
            <table>
              <tr>
                <td>
                  <img src={us7} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  {" "}
                  <img src={us8} alt="logo" className="sbrandslogo" />
                </td>

                <td>
                  {" "}
                  <img src={us9} alt="logo" className="sbrandslogo" />
                </td>
              </tr>
              <tr>
                <td>
                  <img src={fda} alt="logo" className="brandslogo" />
                </td>
                <td>
                  {" "}
                  <img src={Amerigroup} alt="logo" className="sbrandslogo" />
                </td>

                <td>
                  {" "}
                  <img src={MCH} alt="logo" className="brandslogo" />
                </td>
              </tr>
              <tr>
                <td>
                  <img src={kerzner} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  {" "}
                  <img src={solligenix} alt="logo" className="brandslogo" />
                </td>
                <td>
                  <img src={citrix} alt="logo" className="brandslogo" />
                </td>
              </tr>
              <tr>
                <td>
                  <img src={hoerbiger} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  <img src={bc} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  {" "}
                  <img src={fdoffs} alt="logo" className="brandslogo" />
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <img src={us5} alt="logo" className="brandslogo" />
                </td>
                <td>
                  {" "}
                  <img src={us2} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  {" "}
                  <img src={us3} alt="logo" className="sbrandslogo" />
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <img src={us4} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  {" "}
                  <img src={us1} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  {" "}
                  <img src={us6} alt="logo" className="sbrandslogo" />
                </td>
              </tr>
            </table>
          </div>
          <div></div>
        </div>

        <div className="qatar-clients market-client reveal">
          <h2>eWorx clients in Qatar through our marketing partners</h2>

          <div>
            <table>
              <tr>
                <td>
                  <img src={Qatalum} alt="logo" className="brandslogo" />
                </td>
                <td>
                  <img src={qatar4} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  <img src={qatar6} alt="logo" className="brandslogo" />
                </td>
              </tr>
              <tr>
                <td>
                  <img src={qatar2} alt="logo" className="brandslogo" />
                </td>
                <td>
                  <img src={huawei} alt="logo" className="brandslogo" />
                </td>
                <td>
                  <img src={qatar3} alt="logo" className="brandslogo" />
                </td>
              </tr>
              <tr>
                <td>
                  <img src={qatar7} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  <img src={qatar9} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  <img
                    src={qatar8}
                    alt="logo"
                    className="sbrandslogo qatar-icon"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    src={qatar12}
                    alt="logo"
                    className="brandslogo qatar-icon"
                  />
                </td>
                <td>
                  <img
                    src={qatar10}
                    alt="logo"
                    className="sbrandslogo qatar-icon"
                  />
                </td>
                <td>
                  <img
                    src={qatar13}
                    alt="logo"
                    className="brandslogo qatar-icon"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="pak-clients market-client reveal">
          <h2>eWorx clients in Pakistan</h2>

          <div>
            <table>
              <tr>
                <td>
                  <img src={ptcl} alt="logo" className="brandslogo" />
                </td>
                <td>
                  <img src={pk4} alt="logo" className="brandslogo" />
                </td>
                <td>
                  <img src={pk5} alt="logo" className="sbrandslogo" />
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pk6} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  <img src={huawei} alt="logo" className="brandslogo" />
                </td>
                <td>
                  <img src={pk3} alt="logo" className="brandslogo" />
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pk7} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  <img src={pk8} alt="logo" className="brandslogo" />
                </td>
                <td>
                  <img src={pk9} alt="logo" className="brandslogo" />
                </td>
              </tr>

              <tr>
                <td>
                  <img src={pk10} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  <img src={pk11} alt="logo" className="sbrandslogo" />
                </td>
                <td>
                  <img src={pk12} alt="logo" className="sbrandslogo" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
