import React from "react";
import '../css/about.css';

export default function Privacy() {
  return (
    <>
      <div className="privacy">
        <h1>Online Privacy Statement</h1>
        <p>
          <span className="arrow">&#10148; </span>
          eWorx International respects privacy of the individuals/businesses who
          visit our web site. Some visitors may consider registering for special
          offers or services offered on our web site. Any data collected in the
          registration will be used only for the purpose of enabling the visitor
          to register for the offer/service and will not be used in any
          subsequent information external to eWorx.
        </p>
        <p>
          <span className="arrow">&#10148; </span>
          eWorx recognizes its responsibility of protecting the online privacy
          of your personal information. We will never sell or provide your
          collected information to other companies except as necessary to carry
          out our business or as required by law or other legal processes.
        </p>
        <p>
          <span className="arrow">&#10148; </span>
          Should you wish to be removed from our visitors database, please send
          an email to <b style={{color:"var(--blue)"}}>info@eWorxintl.com.pk</b> and we will promptly remove you.
        </p>
        <h2 className="m-3"><b>Terms of Use</b></h2>
        <p>
          <span className="arrow">&#10148; </span>
          Your access to and use of this site is subject to the following terms
          and conditions and all applicable laws. By accessing and using this
          site, you accept the following terms and conditions, without
          limitation or qualification:
        </p>
        <p>
          <span className="arrow">&#10148; </span>
          Unless otherwise stated, the contents of this site including, but not
          limited to, the text and images contained herein and their arrangement
          are the property of eWorx International. All trademarks used or
          referred to in this website are the property of their respective
          owners.
        </p>
        <p>
          <span className="arrow">&#10148; </span>
          Nothing contained in this site shall be construed as conferring by
          implication, estoppels, or otherwise, any license or right to any
          copyright, patent, trademark or other proprietary interest of eWorx
          International or any third party. This site and the content provided
          in this site, including, but not limited to, graphic images, audio,
          video, HTML code, buttons, and text, may not be copied, reproduced,
          republished, uploaded, posted, transmitted, or distributed in any way,
          without the prior written consent of eWorx International, except that
          you may download, display, and print one copy of the materials on any
          single computer solely for your personal, non-commercial use, provided
          that you do not modify the material in any way and you keep intact all
          copyright, trademark, and other proprietary notices.
        </p>
        <p>
          <span className="arrow">&#10148; </span>
          The information provided on this site is free of charge and for
          informational purposes only and does not create a business or
          professional services relationship between you and eWorx
          International. Links on this site may lead to services or sites not
          operated by eWorx International. No judgment or warranty is made with
          respect to such other services or sites and eWorx International takes
          no responsibility for such other sites or services. A link to another
          site or service is not an endorsement of that site or service. Any use
          you make of the information provided on this site, or any site or
          service linked to by this site, is at your own risk.
        </p>
        <p>
          <span className="arrow">&#10148; </span>
          This site and its contents are provided "as is" and eWorx
          International makes no representation or warranty of any kind with
          respect to this site or any site or service accessible through this
          site. eWorx International expressly disclaims all expressed and
          implied warranties including, but not limited to, the implied
          warranties of merchantability, fitness for a particular purpose,
          title, and non-infringement. In no event will eWorx International be
          liable to any party for any direct, indirect, incidental, special,
          exemplary, consequential, or other damages (including, but not limited
          to, lost profits, business interruption, loss of programs or data)
          without regard to the form of action and whether in contract, tort,
          negligence, strict liability, or otherwise, arising out of or in
          connection with this site, any content on or accessed through this
          site or any site service linked to, or any copying, displaying, or use
          thereof.
        </p>
        <p>
          <span className="arrow">&#10148; </span>
          eWorx International maintains this site in Lahore, Pakistan. You agree
          that the terms of use and any legal action or proceeding relating to
          this site shall be governed by the laws of Pakistan without reference
          to its choice of rules. If you attempt to bring any legal proceedings
          against eWorx International you specifically acknowledge that eWorx
          International is free to choose the jurisdiction of its preference as
          to where such action against it may be held. As you have agreed by
          using this site to choose the laws of Pakistan to govern any such
          proceedings, we will probably choose to defend any such action in
          Lahore, Pakistan and can make this decision entirely as it suits us,
          without regard to where in the world you are located, or from where in
          the world you visited this site.
        </p>
        <p>
          <span className="arrow">&#10148; </span>
          You are responsible for complying with the laws of the jurisdiction
          from which you are accessing this site and you agree that you will not
          access or use the information on this site in violation of such laws.
          Unless stated otherwise herein, any information submitted by you
          through this site shall be deemed non-confidential and
          non-proprietary. You represent that you have the lawful right to
          submit such information and agree that you will not submit any
          information unless you are legally entitled to do so. Because of the
          open nature of the Internet, we recommend that you not submit
          information you consider confidential.
        </p>
        <p>
          <span className="arrow">&#10148; </span>
          eWorx International does not accept unauthorized idea submissions
          outside of established business relationships. To protect the
          interests of our current clients and ourselves, we must treat the
          issue of such submissions with great care. Importantly, without a
          clear business relationship, eWorx International cannot and does not
          treat any such submissions in confidence. Accordingly, please do not
          communicate unauthorized idea submissions to eWorx International
          through this website. Any ideas disclosed to eWorx International
          outside a pre-existing and documented confidential business
          relationship are not confidential and eWorx International may
          therefore develop, use and freely disclose or publish similar ideas
          without compensating you or accounting to you. eWorx International
          will make every reasonable effort to return or destroy any
          unauthorized idea submissions without detailed review of them.
          However, if a review is necessary in the sole discretion of eWorx
          International, it will be with the understanding that eWorx
          International assumes no obligation to protect the confidentiality of
          your idea or compensate you for its disclosure or use. By submitting
          an idea or other detailed submission to eWorx International through
          this website, you agree to be bound by the terms of this stated
          policy.
        </p>
      </div>
    </>
  );
}
