import React from "react";
import "../../css/services.css";
import "../../css/mediaquery.css";
import pic3 from "../../assets/images/pic3.png";
import pic4 from "../../assets/images/pic4.png";
import pic from "../../assets/images/pic.png";
import pic5 from "../../assets/images/1.png";
import pic6 from "../../assets/images/2.png";

export default function RemoteStaff() {
  return (
    <div className="staffing " id="remoteStaff">
      <h1 class="mt-5">Remote Staffing Service</h1>
      <section className="staffing-text">
        <div className="txt">
          <p>
            We have been serving our customers internationally with our Remote
            Staffing services with a wide variety of skill sets since{" "}
            <b>January 1997</b>. A good selection process is key to finding
            talented and skilled staff which is suitable and right match for our
            customers job openings.{" "}
          </p>
          <p>
            We at eWorx follow a careful <b>7 stage staff selection process</b>{" "}
            as follows:
            <ul>
              <li>
                <span className="arrow">&#10148; </span>Application
              </li>
              <li>
                <span className="arrow">&#10148; </span>Screening and pre
                selection{" "}
              </li>
              <li>
                <span className="arrow">&#10148; </span>Interview
              </li>
              <li>
                <span className="arrow">&#10148; </span>Assessment
              </li>
              <li>
                <span className="arrow">&#10148; </span>References and
                background check
              </li>
              <li>
                <span className="arrow">&#10148; </span>Decision
              </li>
              <li>
                <span className="arrow">&#10148; </span>Job offer and Contract
              </li>
            </ul>
          </p>
        </div>
        <div>
          <img src={pic} alt="" className="pic" />
        </div>
      </section>
      <section className="benefits">
        <h1>
          <b>Benefits our customers get :</b>
        </h1>
        <p>
          eWorx Remote staffing services benefits our customers in following
          ways:
        </p>

        <ul>
          <li>
            <p>
              <b>Access to vetted and certified talent pool :</b> eWorx offers
              our customers immediate access to a vast and high quality talent
              pool at an affordable cost.
            </p>
          </li>

          <li>
            <p>
              <b>No recruitment hassles : </b> eWorx Remote staffing services
              save our customers the hassle of searching for and hiring vetted
              and certified skillful talent. We take over the Screening and
              evaluation process and send our customers only top class approved
              CV/ resumes.
            </p>
          </li>

          <li>
            <p>
              <b>Legal headache finish : </b> eWorx Remote staffing services
              finishes alot of legal headaches of our customers since eWorx
              remain the original employer of your hire. We take care of all
              documentation and legal responsibilities.
            </p>
          </li>

          <li>
            <p>
              <b>Easy scale up and scale down : </b> eWorx Remote staffing
              services helps our customers easily scale up and add staff
              resources to their team for any specific duration for speeding up
              delivery of their project. And than scale down easily as per
              requirement and demand of the project.
            </p>
          </li>

          <li>
            <p>
              <b>Cost effective : </b> eWorx Remote staffing services helps our
              customers save money by facilitating them in hiring trained staff
              for short term, medium term or long term instead of full time
              staff. Our customers do not have to invest in recruitment,
              employee benefits, taxes and more. Its a very easy and cost
              effective way of getting the job done.
            </p>
          </li>
          <li>
            <p>
              <b>Lower operational costs : </b> eWorx Remote staffing services
              helps our customers reduce their spending by eliminating the costs
              associated with office space, equipment, taxes and many other
              overheads. As a result our customers experience increase in their
              profit margins manifold.
            </p>
          </li>
        </ul>
      </section>
      <section className="talentpool mt-5 reveal">
        <div>
          {" "}
          <h1>
            <b>eWorx Talent Pool :</b>
          </h1>
          <p>
            Below is list of eWorx talent pool skillsets available for helping
            our customers achieve their business objectives:
            <ul>
              <li>
                <span className="arrow">&#10148; </span>Software engineers,
                Network engineers, IT systems support staff{" "}
              </li>
              <li>
                <span className="arrow">&#10148; </span>Electrical engineers
              </li>
              <li>
                <span className="arrow">&#10148; </span>Civil engineers
              </li>
              <li>
                <span className="arrow">&#10148; </span>Mechanical engineers
              </li>
              <li>
                <span className="arrow">&#10148; </span>Chemical engineers
              </li>
              <li>
                <span className="arrow">&#10148; </span>Architects
              </li>
              <li>
                <span className="arrow">&#10148; </span>Accounting and tax
                advisory
              </li>
              <li>
                <span className="arrow">&#10148; </span>Digital marketing
                services, marketing and sales call staff
              </li>
              <li>
                <span className="arrow">&#10148; </span>Website developer
              </li>
              <li>
                <span className="arrow">&#10148; </span>Data Entry Staff
              </li>
              <li>
                <span className="arrow">&#10148; </span>Receptionist
              </li>
              <li>
                <span className="arrow">&#10148; </span>Executive Secretaries
              </li>
              <li>
                <span className="arrow">&#10148; </span>Office administration
                assistants
              </li>
              <li>
                <span className="arrow">&#10148; </span>Lawyer
              </li>
              <li>
                <span className="arrow">&#10148; </span>Project Manager
              </li>
            </ul>
            and many more skillsets available remotely as per customer
            requirements.
          </p>
        </div>
        <div className="staff-img">
          <img src={pic6} alt="" className="pic6" />
          <img src={pic4} alt="" className="pic4" />
          <img src={pic3} alt="" className="pic3" />
          <img src={pic5} alt="" className="pic5" />
        </div>
      </section>
    </div>
  );
}
