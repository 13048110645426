import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import digitalMarket1 from "../../assets/images/digital-market3-removebg-preview.png";
import digitalMarket3 from "../../assets/images/digital-market3.png";
import digitalMarket5 from "../../assets/images/digital-market5.png";
import digitalMarket6 from "../../assets/images/digitalMarket6.png";

export default function digitalMarket() {
  return (
    <div className="page services-div digital-marketing">
      <h1>Digital marketing services</h1>
      <section className="marketing-services-detail">
        <div>
          <h2>eWorx Digital Marketing Services</h2>{" "}
          <p>
            {" "}
            <ul>
              <div>
                <div className="points">
                  <li>
                    <b>Social Media Marketing (SMM) </b>
                    In today's world, social media is a driving force for brand
                    engagement and customer interaction. Our SMM services focus
                    on creating and managing your online presence on platforms
                    like Facebook, Twitter, Instagram, and LinkedIn. We'll help
                    you engage with your audience, increase brand awareness, and
                    drive traffic to your website.
                  </li>
                  <li>
                    <b>Pay-Per-Click Advertising (PPC) </b>We create effective
                    PPC campaigns to help your business appear at the top of
                    search engine results instantly. This is a cost-effective
                    way to increase your website's visibility and drive targeted
                    traffic.
                  </li>
                  <li>
                    <b>Content Marketing </b>Engaging, valuable content is
                    crucial for attracting and retaining customers. Our content
                    marketing team creates high-quality blog posts, articles,
                    videos, and more to establish your brand as an industry
                    authority.
                  </li>{" "}
                  <li>
                    <b>Online Advertising </b>We specialize in various online
                    advertising channels to reach your target audience
                    effectively.
                  </li>
                </div>
                <div className="imgs">
                  <img src={digitalMarket3} alt="" className="dm-3" />
                </div>
              </div>
            </ul>
          </p>
        </div>
      </section>
      <section className="digital-service reveal">
        <div className="ad-div">
          <br />
          <h3>Our Advertising Services include:</h3>
          <p>
            <ul>
              <li>
                <b>Facebook Ads: </b>With targeted Facebook advertising, we can
                put your message in front of the right audience, increasing
                brand awareness and driving traffic to your website.
              </li>
              <li>
                <b>Instagram Ads: </b>Leverage the visual appeal of Instagram to
                showcase your products or services. Our Instagram advertising
                strategies are designed to captivate and engage potential
                customers.
              </li>
              <li>
                <b>YouTube Ads: </b>Video advertising on YouTube is a powerful
                way to reach a vast and engaged audience. We create compelling
                video ads that drive results.
              </li>
              <li>
                <b>Google Ads: </b>Place your business at the top of Google
                search results and gain more visibility with our Google Ads
                expertise. We optimize your ads to ensure you get the most out
                of your advertising budget.
              </li>
              <li>
                <b>Increase Brand Visibility: </b>We will help you create and
                maintain an engaging presence on social media platforms,
                ensuring your brand reaches a wider audience.
              </li>
              <li>
                <b>Engage Your Audience: </b>Our team will curate and share
                content that resonates with your target audience, sparking
                meaningful conversations and interactions.
              </li>
              <li>
                <b>Drive Traffic: </b>Through strategic social media campaigns,
                we will steer potential customers to your website, increasing
                your online traffic.
              </li>
            </ul>
          </p>
        </div>
        <div className="digital-img">
          <img src={digitalMarket1} alt="" className="digitalMarket1" />
          {/* <img src={digitalMarket2} alt="" className="digitalMarket2" /> */}
        </div>
      </section>
      <section className="digital-why reveal">
        <div>
          <h2>Why Choose eWorx for Digital Marketing?</h2>
          <p>
            <ul>
              <li>
                <b>Experience: </b>Our team of experts has years of experience
                in digital marketing, ensuring you receive top-notch services.
              </li>
              <li>
                <b>Customized Solutions: </b>We understand that every business
                is unique. We tailor our strategies to suit your specific needs
                and goals.
              </li>
              <li>
                <b>Proven Results: </b>Our track record speaks for itself. We
                have helped numerous businesses achieve their digital marketing
                objectives.
              </li>
            </ul>
          </p>
          <p>
            If you're ready to take your business to the next level with digital
            marketing and online advertising, contact us today. We'll work
            closely with you to develop a strategy that delivers real results
            and helps your business succeed in the digital age.
          </p>
          <p>
            Join the digital revolution with{" "}
            <span className="blue">
              <b>eWorx</b>
            </span>{" "}
            and stay ahead of the competition. Let's build your online presence
            together.
          </p>
        </div>
        <div className="why-img">
          <img src={digitalMarket5} alt="" />
        </div>
      </section>
      <section className="digital-benifits reveal">
        <div>
          <p>
            <h3>
              <span className="arrow">&#10148; </span>
              <b>Tailored Strategies: </b>
            </h3>
            We understand that every business is unique. That' s why we create
            customized strategies that align with your specific goals and
            budget.
          </p>
          <p>
            <h3>
              <span className="arrow">&#10148; </span>
              <b>Measurable Results: </b>
            </h3>
            We believe in the power of data. Our results-driven approach ensures
            that your digital marketing efforts can be tracked and measured,
            allowing for continuous improvement.
          </p>
          <p>
            <h3>
              <span className="arrow">&#10148; </span>
              <b>Affordable Packages:</b>
            </h3>
            Our services are cost-effective and designed to provide maximum
            value for your investment.
          </p>
        </div>
      </section>
      <section className="digital-contact reveal">
        <h2>Get Started Today!</h2>
        <p>
          In the digital age, your online presence can make or break your
          business. At eWorx, we are committed to helping you shine online
          through Digital Marketing, including Social Media Marketing. Contact
          us today, and let's explore how we can elevate your brand' s presence
          on the web. Your success is our priority!
        </p>
        <p>
          {/* eslint-disable-next-line */}
          <Link to="/contact" style={{ color: "var(--blue)" }}>
            <b>Contact Us</b>
          </Link>{" "}
          to discuss your digital marketing needs. We look forward to being your
          partner in digital growth!
        </p>
      </section>
    </div>
  );
}
