import React from "react";
import softdev1 from "../../assets/images/Untitled design (7).png";
import softdev2 from "../../assets/images/softdev1.png";
import softdev4 from "../../assets/images/softdev4.png";

export default function SoftwareDev() {
  return (
    <div className="services-div  software-dev">
      <h1>Software Development Services</h1>
      <div>
        <div className="soft-dev-flex softdev-intro">
          <div>
            {" "}
            <section className="soft-dev-intro">
              <p>
                {" "}
                At <span className="blue">eWorx International</span>, we take
                pride in our comprehensive suite of cutting edge software
                development solutions designed to transform your business. Our
                team of seasoned professionals is dedicated to crafting
                innovative, custom software that caters specifically to your
                unique needs and goals.
              </p>
            </section>
            <section className="soft-dev-solutions">
              <h2>Tailored Solutions for Your Business Needs</h2>
              <p>
                We understand that every business operates differently, which is
                why we don't believe in one-size-fits-all solutions. Whether you
                are a startup looking to establish your digital presence or a
                well-established enterprise seeking to optimize operations, our
                tailored software development services are crafted to address
                your specific challenges and drive measurable results.
              </p>
            </section>
          </div>
        </div>

        <section className="soft-dev-approach ">
          <h2>Our Approach</h2>
          <p>
            Collaboration lies at the heart of our process. We work closely with
            our clients to understand their vision, objectives, and pain points.
            This collaborative approach ensures that our solutions align
            perfectly with your business goals. Our expertise spans across
            various domains, including <b>custom software development.</b>
            {/* <ul>
              <li>
                <b>
                  <span className="blue" style={{ fontSize: "20px" }}>
                    {" "}
                    &#60;&#62; Custom Software Development:&nbsp;
                  </span>
                </b>
                From ideation to deployment, we build{" "}
                <b>robust, scalable, and intuitive software solutions </b>{" "}
                tailored to your requirements.
              </li>
            </ul> */}
          </p>
          <div className="soft-dev-flex">
            <div className="custom-software">
              <h2>Custom Software Development</h2>

              <p>
                <ul>
                  <li>
                    <span className="arrow">&#10148; </span>
                    <b>Requirement Analysis: </b> Understanding clients
                    requirements{" "}
                    <span className="blue">
                      (i.e the goals, objective and the scope)
                    </span>{" "}
                    of the project indepth to have better understanding of our
                    client demands towards us.
                  </li>
                  <li>
                    <span className="arrow">&#10148; </span>
                    <b>Project Planning: </b>eWorx provide its clients a
                    detailed project plan outlining project timelines,
                    milestones and deliverables.
                  </li>
                  <li>
                    <span className="arrow">&#10148; </span>
                    <b>Technology Stack: </b>We always use the{" "}
                    <span className="blue">right technology</span> to develop
                    the client's desired software application according to the
                    project's requirements.
                  </li>
                  <li>
                    <span className="arrow">&#10148; </span>
                    <b>User Experience (UX) and User Interface (UI): </b>eWorx
                    promises its clients to develop software applications with
                    creative, intuitive, and visually appealing interfaces and
                    attractive designs.
                  </li>
                  <li>
                    <span className="arrow">&#10148; </span>
                    <b>Customization and Scalabilty: </b>Our software developers
                    team develop customizable software to accomodate future
                    changes as well as ensure scalabilty to handle growth.
                  </li>
                </ul>
              </p>
            </div>
            <div className="soft-img">
              <img src={softdev2} alt="" className="softdev2" />
            </div>{" "}
          </div>
        </section>
        <section className="soft-dev-why reveal soft-dev-flex">
          <div>
            <h2>Why Choose eWorx International?</h2>
            <p>
              <ul>
                <li>
                  <b>
                    <span className="blue "> &#60;&#62; Expert Team: </span>
                  </b>{" "}
                  Our team comprises skilled developers, designers, and project
                  managers experienced in leveraging the latest technologies and
                  best practices.
                </li>
                <li>
                  <b>
                    <span className="blue ">
                      {" "}
                      &#60;&#62; Quality Assurance:{" "}
                    </span>
                  </b>{" "}
                  We adhere to rigorous testing protocols to ensure that our
                  software meets the highest standards of quality and
                  reliability.
                </li>
                <li>
                  <b>
                    <span className="blue "> &#60;&#62; Timely Delivery: </span>
                  </b>{" "}
                  We value your time and strive to deliver projects within
                  agreed timelines without compromising on quality.
                </li>
                <li>
                  <b>
                    <span className="blue ">
                      {" "}
                      &#60;&#62; Continuous Support:{" "}
                    </span>
                  </b>{" "}
                  Our commitment doesn't end with project delivery. We provide
                  ongoing support and maintenance to ensure seamless operations.
                </li>
              </ul>
            </p>
          </div>
          <div className="soft-dev-image">
            <img src={softdev1} alt="" className="softdev1" />
          </div>
        </section>

        <section className="soft-dev-innovate reveal">
          <h2>Let's Innovate Together</h2>
          <p>
            Embark on a journey of digital transformation with{" "}
            <span className="blue">eWorx International</span>. Whether you
            require{" "}
            <b>a custom software solution, web application, or mobile app, </b>
            we are here to turn your vision into reality. Get in touch with us
            today to discuss how our software development services can elevate
            your business to new heights.
          </p>
        </section>
      </div>
    </div>
  );
}
