import React from "react";
import "../css/footer.css";
import image from "../assets/images/logo.png";
import facebook from "../assets/images/facebook.png";
import linkedin from "../assets/images/linkedin.png";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import email from "../assets/images/email.png";
import whatsapp_btn from "../assets/images/whatsapp_btn.png";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="footer-text">
          <div className="company-info">
            <img src={image} alt="logo" className="logo" />
            <p>
              Eworx is over 2 decades old premier IT enabled services company
              serving the local as well as international markets in the English
              speaking world.
            </p>

            <Link to="/privacypolicy">Privacy Policy and Terms of Use</Link>
          </div>
          <div className="social-links">
            <div className="webpage-links">
              <ul>
                <Link to="/">
                  <li>Home</li>
                </Link>

                <Link to="/about">
                  <li>About</li>
                </Link>

                <Link to="/successstories">
                  <li>Clients</li>
                </Link>

                <Link to="/faqs">
                  <li>FAQ</li>
                </Link>

                <Link to="/career">
                  <li>Career</li>
                </Link>

                <Link to="/contact">
                  <li>Contact</li>
                </Link>
                <Link to="/testimonials">
                  <li>Testimonials</li>
                </Link>

                <Link to="/webDev">
                  <li>Website Development Services</li>
                </Link>

                <Link to="/digitalMarket">
                  <li>Digital Marketing Services</li>
                </Link>

                <Link to="/Accounting">
                  <li>Accounting and Tax Advisory Services</li>
                </Link>

                <Link to="/remoteStaff">
                  <li>Remote Staffing Services</li>{" "}
                </Link>

                <Link to="/medical">
                  <li>Medical Transcription and Billing Services</li>
                </Link>

                <Link to="/softwareDev">
                  <li>Software Development Services</li>
                </Link>
              </ul>
            </div>
            <div className="socialmedia-links">
              <a href="https://www.facebook.com/eworxinternationallimited">
                <img src={facebook} alt="icons" className="icons-link" />
              </a>
              <a href="https://www.linkedin.com/company/eworx-international/">
                <img src={linkedin} alt="icons" className="icons-link" />
              </a>
              <a href="mailto:info@eWorxintl.com.pk">
                <img src={email} alt="icons" className="icons-link" />
              </a>
            </div>
          </div>
        </div>
        <div className="rights">
          Copy Right &copy; 2024 eWorx - All rights reserved
        </div>
      </div>
      <div className="whatsapp-div">
        <div className="whatsapp-text">
          Chat with us!
        </div>
        <a
          href="https://wa.me/+92xxxx"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={whatsapp_btn}
            alt="whatsapp button"
            className="whatsapp_btn"
          />
        </a>
      </div>
    </>
  );
}
