import React from "react";
import acc1 from "../../assets/images/acc-1.png" 
export default function Accounting() {
  return (
    <>
      <div className="accounting page services-div">
        <h1> Accounts and Tax Advisory</h1>

        <section className="account-section">
          <div className="account-text">
            <p>
              We are proud to offer a highly skilled and experienced team of
              virtual accountants to help our clients with all of their
              accounting needs. Our team is knowledgeable in a wide range of
              accounting practices and technologies, and we have a strong track
              record of delivering accurate and timely financial reports and
              solutions.
            </p>
            {/* <img src={acc2} alt="" style={{height:"300px"}} /> */}
          </div>
          <div>
            <p  className="account-serivces">

              <ul>
              Some of the services our virtual accounting team can provide
              include:
                <li><span className="arrow">&#10148; </span>
                  <b>Budgeting :</b> Our team can assist with budgeting and
                  costing of the projects over the period by monthly, quarterly
                  and yearly.
                </li>
                <li><span className="arrow">&#10148; </span>
                  <b>Invoicing :</b> We can help you keep your client’s record up
                  to date, including invoicing, aging & collection by
                  reconciling bank statements, and preparing financial
                  statements.
                </li>
                <li><span className="arrow">&#10148; </span>
                  <b>Expenses :  </b>We can help you keep your
                  contractors/vender’s record up to date, including cost of
                  goods sold, operating expenses & all cash-out transactions by
                  cash, cheque or credit cards and preparing financial
                  statements.
                </li>
                <li><span className="arrow">&#10148; </span>
                  <b>Payroll :</b> We can help you process payroll by time sheet
                  management, including calculating pay, preparing paychecks,
                  and filing tax documents
                </li>
                <li><span className="arrow">&#10148; </span>
                  <b>Book keeping :</b> We can help you keep your financial
                  records up to date, including tasks such as entering
                  transactions, reconciling accounts, and preparing financial
                  statements.
                </li>
                <li><span className="arrow">&#10148; </span>
                  <b>Tax preparation :</b> Our team can assist with tax
                  preparation and filing, ensuring that you are in compliance
                  with all tax laws and regulations.
                </li>
                <li><span className="arrow">&#10148; </span>
                  <b>Financial analysis :</b> We can provide insights and
                  recommendations based on your financial data, helping you make
                  informed business decisions.
                </li>
                <li><span className="arrow">&#10148; </span>
                  <b>Reporting :</b> Our team will be reporting on weekly,
                  semi-monthly, monthly, quarterly and yearly basis on each
                  financial module in numbers & graphs, in real user-friendly
                  formats which will help you to make future strategic
                  decisions.
                </li>
              </ul>
            <img src={acc1} alt="" className="acc-img" />
            </p>
            <p>
              If you are in need of virtual accounting services, we encourage
              you to contact us to learn more about how we can help. We are
              committed to delivering the best possible service to our clients
              and are confident that our team can meet your accounting needs. <i> We
              look forward to working with you!</i>
            </p>
          </div>
        </section>
      </div>
    </>
  );
}
