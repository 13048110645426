import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import webdev1 from "../../assets/images/web-dev1.png";
import webdev3 from "../../assets/images/webdev3.png";

export default function webDev() {
  return (
    <div className="page web-dev services-div">
      <h1>Website Development Services</h1>
      <section>
        <div className="web-dev-intro">
          <p>
            At <span className="blue">eWorx</span>, We understand the power of a
            strong online presence. Your website is often the first interaction
            a potential client has with your business, and we are here to ensure
            that it leaves a lasting impression. Our dedicated team of skilled
            web developers is ready to transform your digital vision into a
            reality.
          </p>
        </div>
        <div className="web-dev-services">
          <div>
            <h2>eWorx Website Development Services</h2>
            <p>
              <ul>
                <li>
                  <span className="blue">
                    <b>&#60;&#62;</b>
                  </span>
                  <b> User Experience (UX) and User Interface (UI) Design: </b>
                  Focusing on creating an intuitive and visually appealing
                  experience for website visitors, considering factors like
                  navigation, accessibility, and aesthetics.
                </li>
                <li>
                  {" "}
                  <span className="blue">
                    <b>&#60;&#62;</b>
                  </span>
                  <b> Responsive Layouts: </b>
                  Crafting fluid and adaptive layouts that dynamically adjust to
                  different screen dimensions, and
                  visually appealing experience whether accessed on desktops,
                  tablets, or smartphones.{" "}
                </li>
                <li>
                  <span className="blue">
                    <b>&#60;&#62;</b>
                  </span>{" "}
                  <b> SEO (Search Engine Optimization) :</b> Optimizing the
                  website's structure, content, and performance to improve its
                  visibility on search engines and drive organic traffic.
                </li>
                <li>
                  <span className="blue">
                    <b>&#60;&#62;</b>
                  </span>
                  <b> Security: </b> Implementing security measures to protect
                  the website from common vulnerabilities, such as SSL
                  certificates, firewalls, and regular security updates.
                </li>
                <li>
                  <span className="blue">
                    <b>&#60;&#62;</b>
                  </span>
                  <b> Website Maintenance: </b>Providing ongoing maintenance
                  services to keep the website up-to-date, secure, and free of
                  issues, including regular backups and updates.
                </li>
                <li>
                  <span className="blue">
                    <b>&#60;&#62;</b>
                  </span>
                  <b> Website Performance Optimization: </b>Enhancing the website's
                  speed, load times, and overall performance to provide a better
                  user experience and boost search engine rankings.
                </li>
                <li>
                  <span className="blue">
                    <b>&#60;&#62;</b>
                  </span>
                  <b> Database Management: </b>Handling the organization,
                  storage, and retrieval of data on the website, especially for
                  dynamic and data-driven websites
                </li>
              </ul>
            </p>
          </div>
          <div>
            <img src={webdev3} alt="" className="webdev" id="webdev3" />
          </div>
        </div>
        <div className="web-dev-why reveal">
          <div>
            <h2>Why choose eWorx for Website Development?</h2>
            <p>
              <ul>
                <li>
                  <span className="blue">
                    <b>&#60;&#62;</b>
                  </span>
                  <b> Expertise: </b>Our team comprises seasoned web developers
                  with a wealth of experience in the industry. We stay updated
                  with the latest trends and technologies to deliver cutting edge
                  solutions.
                </li>
                <li>
                  <span className="blue">
                    <b>&#60;&#62;</b>
                  </span>
                  <b> Collaboration: </b>We believe in a collaborative approach.
                  Your input is invaluable, and we work closely with you
                  throughout the development process to ensure your vision is
                  realized.
                </li>
                <li>
                  <span className="blue">
                    <b>&#60;&#62;</b>
                  </span>
                  <b> Experience: </b>Our team of experts has years of
                  experience in Web development , ensuring you receive top-notch
                  services.
                </li>
                <li>
                  <span className="blue">
                    <b>&#60;&#62;</b>
                  </span>
                  <b> Timely Delivery: </b>We understand the importance of
                  timelines. Our agile development process ensures that
                  your project is delivered on schedule without compromising
                  quality
                </li>
                <li>
                  <span className="blue">
                    <b>&#60;&#62;</b>
                  </span>
                  <b> Cost-Effective Solutions: </b>We believe in delivering
                  cost effective solutions without compromising on quality. Our
                  competitive pricing ensures that you receive exceptional value
                  for your investment.
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className="web-dev-contact reveal">
          <h2>Let's start your Website Development Journey with eWorx</h2>
          <p>
            Ready to enhance your online presence? Connect with us for a
            complimentary consultation. Discover how our web development
            services can propel your business forward.
            <br />
            <Link to="/contact" style={{ color: "var(--blue)" }}>
              <b>Contact Us</b>
            </Link>{" "}
            to discuss your website development needs. We look forward to being your partner
            in digital growth!
          </p>
        </div>
        <div className="webdev-img reveal">
          <img src={webdev1} alt="" />
        </div>
      </section>
    </div>
  );
}
